import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import React, { useState } from "react";
import hamburger from "../../assets/hamburger.svg";
import headerLogo from "../../assets/headerLogo.svg";
import { verticalPadding, verticalMargin } from "../../margins";
import { bpLarge, bpMedium, bpSmall, colorBlue, colorDark, colorGrayFill } from "../../stylingConstants";
import { LinkButton } from "../Atoms/Button";
import MaybeExternalLink from "../Atoms/MaybeExternalLink";
import { displayTypeface, textTypeface } from "../Atoms/Text";
import SiteNavigation from "./SiteNavigation";
import SocialLinks from "../Atoms/SocialLinks";
import SideMenu from "./SideMenu";
import VideoEmbed from "../Atoms/VideoEmbed";
import Countdown from "../Atoms/Countdown";
import { useGlobalSiteData, useSiteHeaderState } from "../../hooks";

const style = {
  wrapper: css`
    display: "block";
    background-color: ${colorBlue};
    text-align: center;
    color: white;
  `,
  content: css`
    color: ${colorDark};
    padding-top: 16px;
    background-color: ${colorGrayFill};
    @media ${bpMedium} {
      background-color: transparent;
      color: inherit;
      border-top: 1px solid #434657;
    }
  `,
};

const headerLinkCss = css`
  display: none;
  font-family: ${displayTypeface};
  font-size: 17px;
  margin-right: 24px;
  @media ${bpMedium} {
    display: block;
  }
  @media ${bpLarge} {
    font-size: 19px;
    margin-right: 36px;
  }
`;

export const HeaderText = styled.h2`
  font-family: ${textTypeface};
  font-style: italic;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  margin: 0;
  @media ${bpLarge} {
    font-size: 20px;
    line-height: 28px;
  }
`;

const movieDimensionsFromWidth = (width: number) => {
  return css`
    width: ${width}px;
    height: ${(width / 16) * 9}px;
  `;
};

type Props = {
  showEmbed?: boolean;
};

export default function Header({ showEmbed = true }: Props) {
  const { desktopMenuItems } = useGlobalSiteData();
  const headerState = useSiteHeaderState();
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <header css={style.wrapper}>
        <div
          css={css`
            position: relative;
          `}
        >
          <h1
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              ${verticalPadding(16)}
              overflow: hidden;
              max-height: 96px;
              @media ${bpSmall} {
                max-height: 160px;
              }
              @media ${bpMedium} {
                max-height: 212px;
              }
              @media ${bpLarge} {
                max-height: 276px;
              }

              margin: 0;
              font-size: 0;
            `}
          >
            <Link
              to="/"
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
              `}
            >
              <img
                src={headerLogo}
                alt="Stora Journalistpriset"
                css={css`
                  height: 116px;
                  @media ${bpSmall} {
                    height: 128px;
                  }
                  @media ${bpMedium} {
                    height: 180px;
                  }
                  @media ${bpLarge} {
                    height: 242px;
                  }
                `}
              />
            </Link>
          </h1>
          <div
            css={css`
              position: absolute;
              top: 0;
              right: 0;
              box-sizing: border-box;
              padding: 24px;
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: center;
              @media ${bpSmall} {
                justify-content: flex-start;
              }
              @media ${bpLarge} {
                padding: 40px;
              }
            `}
          >
            <nav
              css={css`
                display: flex;
                flex-direction: row;
                flex-grow: 0;
                flex-shrink: 0;
                justify-content: flex-end;
              `}
            >
              {desktopMenuItems.map(
                ({ url, text }) =>
                  url && (
                    <MaybeExternalLink to={url} css={headerLinkCss} key={`${text}/${url}`}>
                      {text}
                    </MaybeExternalLink>
                  )
              )}
              <img src={hamburger} style={{ display: "block", cursor: "pointer" }} onClick={() => setShowMenu(true)} />
            </nav>
          </div>
        </div>
        <div css={style.content}>
          {showEmbed && headerState.embed != null && (
            <VideoEmbed
              css={css`
                ${verticalMargin(16)}
                ${movieDimensionsFromWidth(320)}
                @media ${bpSmall} {
                  ${movieDimensionsFromWidth(420)}
                }
                @media ${bpMedium} {
                  ${movieDimensionsFromWidth(600)}
                }
                @media ${bpLarge} {
                  ${movieDimensionsFromWidth(730)}
                }
              `}
              src={headerState.embed.src}
              thumbSrc={headerState.embed.thumb}
              frameBorder={0}
              allowFullScreen
            />
          )}
          <div
            css={css`
              opacity: ${headerState.loaded ? 1 : 0};
              transition: opacity 250ms;
              box-sizing: border-box;
              padding-bottom: 16px;
              height: 88px;
              @media ${bpLarge} {
                height: 98px;
              }
            `}
          >
            {headerState.loaded && (
              <>
                <HeaderText>{headerState.text}</HeaderText>
                {headerState.open ? (
                  headerState.link && (
                    <LinkButton
                      to={headerState.link.url}
                      css={css`
                        margin-top: 8px;
                      `}
                      placement="onDark"
                    >
                      {headerState.link.text}
                    </LinkButton>
                  )
                ) : (
                  <Countdown timeLeft={headerState.timeLeft} />
                )}
              </>
            )}
          </div>
        </div>
      </header>
      <SideMenu show={showMenu} onClose={() => setShowMenu(false)}>
        <>
          <SiteNavigation theme={"onLight"} css={verticalMargin(-15)} />
          <div>
            <SocialLinks theme={"onLight"} spacious={false} />
          </div>
        </>
      </SideMenu>
    </>
  );
}
